<template>
  <div class="home-component">
    <Slider class="animate__animated animate__bounceIn" />

    <div class="mission-section animate__animated animate__fadeInDown">
      <div class="section-illustrator">
        <img
          class="
            illustrator
            mission-section
            animate__animated animate__fadeInRightBig
          "
          src="@/assets/images/marketplace-animate.svg"
        />
      </div>
      <div class="section-text animate__animated animate__fadeInLeftBig">
        <h3>{{ $t("mission") }}</h3>
        <p>{{ $t("missionText") }}</p>
      </div>
    </div>

    <div class="vision-section animate__animated animate__fadeInDown">
      <div class="section-illustrator">
        <img
          class="illustrator"
          src="@/assets/images/combo-offer-animate.svg"
        />
      </div>
      <div class="section-text">
        <h3>{{ $t("vision") }}</h3>
        <p>{{ $t("visionText") }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Slider from "@/components/general/Slider.vue";

export default {
  name: "HomeComponent",
  components: {
    Slider,
  },
  data() {
    return {
      pageContent: document.querySelector(".page-content"),
      navbarLogin: document.querySelector(".navbar-login"),
    };
  },
  computed: {
    ...mapGetters(["userPersonalData"]),
  },
  methods: {},
};
</script>

<style scoped lang="scss"></style>
